import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { r_classroom, s_classroom, s_evaluation } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import { IMG_ZOOM_GUIDE_CLASSROOM_EN, IMG_ZOOM_GUIDE_CLASSROOM_KR } from '../../../modules/images'
import Button from '../../_legacy/Button'
import Col from '../../basic/Col'
import { FeClose } from '../../common/Icons'
import NewBasicContainer from '../../container/NewBasicContainer'

const ZoomGuideModalBody = ({
  lesson,
  onClose,
  setShowChatBox,
}: {
  lesson: any
  onClose: any
  setShowChatBox: any
}) => {
  const { t, isLocaleKO } = useTranslation()
  // TODO StudentClassRoomBody, TutorClassRoomBody 에는  zoom url 생성 규칙이 아래와 같은데 동일하게 할 필요
  // lesson?.join_url?.split('/')[3] == 'j' ? `${lesson?.join_url}?zak=${lesson?.zoom_url}` : `${lesson?.join_url}`,
  const url = lesson.meeting_number ? `https://zoom.us/j/${lesson.meeting_number}` : ''
  const meeting_number: number = lesson.meeting_number ?? 0
  const handleClickRequestHelp = () => {
    // 팝업 닫고
    // 헬프챗 열기
    onClose()
    setShowChatBox(true)
  }
  return (
    <NewBasicContainer toast={toast}>
      <section>
        <div
          className={`absolute left-[50vw] top-[140px] max-w-[600px] md:top-[160px] md:h-fit ${
            isLocaleKO ? 'xs:h-4/5' : 'xs:h-3/4'
          } z-[1020]`}
          style={{
            transform: 'translateX(-50%)',
            zIndex: 3000,
          }}
        >
          <div className="border-1 mx-auto h-full max-w-[600px] overflow-y-auto overscroll-contain rounded-[5px] border-gray-100 bg-white px-[32px] pt-[32px] pb-[38px] shadow-md">
            <div className="row items-center">
              <Col>
                <div className="text-h2 mb-[0px] font-bold">{t(s_classroom.modal_zoom_title)}</div>
              </Col>
              <Col auto>
                <div onClick={onClose} className="cursor-pointer">
                  <FeClose size={20} />
                </div>
              </Col>
            </div>
            <div className="text-h4 mt-[32px] font-medium">{t(s_classroom.modal_zoom_tap_2)}</div>
            <div className="text-sBody my-[16px]">
              {meeting_number !== 0 ? (
                <>
                  Please click the [Relaunch Zoom] button below. You can also try joining the lesson manually: Launch
                  Zoom {'>'} Click [Join a meeting] {'>'} Enter the meeting ID. If you are asked to register for a
                  meeting, please enter your name and Ringle-registered email address, and click "Register and Join." If
                  you are asked for a meeting passcode, please enter the numbers "12345."
                </>
              ) : (
                t(r_classroom.modal_zoom_desc_4)
              )}
              <div className="row mt-[16px]">
                <Col>
                  <CopyToClipboard
                    text={meeting_number.toString()}
                    onCopy={() => toast(t(r_classroom.link_copied) ?? 'Meeting ID is copied to your clipboard')}
                  >
                    {meeting_number !== 0 && (
                      <div className="mt-[16px] mb-[22px] flex cursor-pointer text-purple-500">
                        <img src="/assets/img/icons/icon-copy.svg" alt="copy" />
                        <span className="ml-[6px]">{t(r_classroom.btn_2(meeting_number))}</span>
                      </div>
                    )}
                  </CopyToClipboard>
                </Col>
                <Col auto>
                  <Button
                    md
                    primary
                    disabled={meeting_number === 0}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      const popup = window.open(url, '_blank')
                      console.info('zoom 강제실행 버튼 클릭 ZoomGuideModalBody', lesson?.id, url)
                      if (!popup) {
                        console.info('zoom 강제실행 버튼 클릭 - 팝업안열림', lesson?.id)
                      }
                    }}
                  >
                    {t(r_classroom.btn)}
                  </Button>
                </Col>
              </div>
            </div>
            <div className="text-h4 font-medium">{t(r_classroom.modal_zoom_tap_1)}</div>
            <div className="text-sBody mt-[8px]">{t(r_classroom.modal_zoom_desc_1)}</div>
            <div className="border-t-1 mt-[16px] h-[1px] w-full border-gray-200" />
            <img src={isLocaleKO ? IMG_ZOOM_GUIDE_CLASSROOM_KR : IMG_ZOOM_GUIDE_CLASSROOM_EN} alt="Zoom Guide" />
            <div className="text-sBody mt-[38px] flex justify-center">
              {t(
                r_classroom.modal_zoom_desc_3(
                  <span
                    className="cursor-pointer text-purple-500 underline underline-offset-2"
                    onClick={handleClickRequestHelp}
                  >
                    {t(s_evaluation.help)}
                  </span>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </NewBasicContainer>
  )
}
export default ZoomGuideModalBody
