// @ts-strict-ignore
import { useRouter } from 'next/router'
import React, { Fragment, ReactElement, useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import Parser from 'react-html-parser'
import Col from '../../components/basic/Col'
import Badge from '../../components/common/Badge'
import GoogleTranslate from '../../components/common/GoogleTranslate'
import { FeClose } from '../../components/common/Icons'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import NoSSR from '../../components/common/NoSSR'
import apis from '../../modules/apis'
import { axiosPost } from '../../modules/axiosHelper'
import LinkLocale from '../../modules/i18n/LinkLocale'
import { s_classroom, s_common, s_preparation } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { DATA_REF_KEY } from '../../modules/ids/e2e/student'
import { RINGLE_LOGO_ROUND } from '../../modules/images'
import { ICON_CHEVRON_LEFT_PRIMARY } from '../../modules/svg/svg'
import urls from '../../modules/urls'
import Button from '../_legacy/Button'
import ZoomGuideModalBody from '../modal/classroom/ZoomGuideModalBody'

export const CONTENT_NAV_BAR_HEIGHT = 70
interface ContentNavBarType {
  onExit?: any
  noExit?: boolean
  onExitContent?: any
  showGoogleTranslate?: boolean
  setShowGoogleTranslate?: any
  setShowZoomGuide?: any
  showZoomGuide?: any
  showChatBox?: any
  setShowChatBox?: any
  chatList?: any
  lesson?: any
  pageType?: string | ReactElement
  description?: any
  isLesson?: boolean
  isPrestudy?: boolean
  isLessonPast?: boolean
  button1?: any
  button2?: any
  button3?: any
  button4?: any
  clx?: string
  location?: any
  isPast?: boolean
  noTopSideTab?: boolean
  homeLink?: any
  TutorChatBox?: any
  exitLabel?: string
}
const ContentNavBar: React.FC<ContentNavBarType> = ({
  onExit,
  noExit = false,
  onExitContent,
  exitLabel,
  showGoogleTranslate,
  setShowGoogleTranslate,
  setShowZoomGuide,
  showZoomGuide,
  showChatBox,
  chatList,
  setShowChatBox,
  lesson,
  pageType,
  description,
  isLesson,
  isPrestudy,
  isLessonPast,
  button1,
  button2,
  button3,
  button4,
  clx,
  location,
  isPast,
  noTopSideTab,
  homeLink = urls.tutor.portal.home,
  TutorChatBox,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <Fragment>
      {showGoogleTranslate && <GoogleTranslate lessonId={lesson?.id} onClose={() => setShowGoogleTranslate(false)} />}
      {showZoomGuide && (
        <ZoomGuideModalBody lesson={lesson} onClose={() => setShowZoomGuide(false)} setShowChatBox={setShowChatBox} />
      )}
      {showChatBox && !TutorChatBox && (
        <ChatBox lesson={lesson} showChatBox={showChatBox} chatList={chatList} onClose={() => setShowChatBox(false)} />
      )}
      {TutorChatBox}
      <div className="border-b-1 fixed top-[0px] left-[0px] right-[0px] z-[60] h-[70px] border-gray-200 bg-gray-50">
        <div className="row h-[70px] items-center pl-24 pr-12">
          {noExit || (
            <Col auto>
              <div
                data-ref={DATA_REF_KEY.btn_exit}
                onClick={onExitContent ? () => onExitContent() : onExit ? onExit : () => router.back()}
                className="flex cursor-pointer flex-row"
              >
                <div className="text-h6 flex items-center text-purple-500">
                  <div className="flex flex-row">
                    <img src={ICON_CHEVRON_LEFT_PRIMARY} className="mr-[12px]" />
                    {exitLabel ? exitLabel : t(s_preparation.exit)}
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Col textCenter>
            <div className="flex items-center justify-start">
              <LinkLocale href={homeLink}>
                <img src={RINGLE_LOGO_ROUND} className="mr-[16px] h-[31px]" />
              </LinkLocale>
              <div className="mr-[24px] whitespace-nowrap font-bold text-gray-700">{pageType}</div>
              <div className="text-sBody mdd:hidden mr-[8px] whitespace-nowrap text-gray-700">{description}</div>
              {(isLesson || isPrestudy) && (
                <Badge twentyLight={lesson?.duration == 20} fortyLight={lesson?.duration == 40} duration>
                  {lesson?.duration}
                </Badge>
              )}
            </div>
          </Col>
          <Col flex auto clx={clx} whitespaceNowrap>
            {button1 && <div className="mr-[8px]">{button1}</div>}
            {button2 && <div className="mr-[8px]">{button2}</div>}
            {button3 && <div className="mr-[8px]">{button3}</div>}
            {button4 && <div className="mr-[8px]">{button4}</div>}
            <div className="mr-[16px]" />
          </Col>
        </div>
      </div>
      {noTopSideTab || <div className="h-[70px]" />}
    </Fragment>
  )
}

export default ContentNavBar

type ChatBoxPropsType = {
  lesson: any
  chatList: any
  showChatBox: any
  onClose: any
}

const ChatBox = ({ lesson, chatList, showChatBox, onClose }: ChatBoxPropsType) => {
  const { t } = useTranslation()
  const chatRef = useRef(null)
  const inputRef = useRef(null)
  const endOfDivRef = useRef(null)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const scrollToEnd = () => {
    endOfDivRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (showChatBox) {
      inputRef.current.focus()
    }
  }, [showChatBox])

  useEffect(() => {
    scrollToEnd()
  }, [chatList])

  const handleStudentChat = async (e, text, type) => {
    e.preventDefault()
    if (text.length > 0) {
      setLoading(true)
      const response = await axiosPost(apis.lessons.chat(), {
        lesson_id: lesson.id,
        user_id: lesson.student_id,
        text: text,
        type: type,
      })
      setLoading(false)
      if (response.success) {
        if (message) {
          setMessage('')
        }
        scrollToEnd()
      }
    }
  }

  // TUTOR
  const today = new Date()
  const [recordTime, setRecordTime] = useState(today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds())

  return (
    <Draggable bounds=".body" handle=".handle">
      <div
        style={{ zIndex: 1070 }}
        className="border-1 absolute top-[10px] right-[10px] w-[480px] rounded-[5px] border-gray-100 bg-white shadow-md"
      >
        <div className="handle cursor-move border-b border-gray-200 p-[32px] pb-[16px]">
          <div className="row items-center py-[3px]">
            <Col heading2 fontBold>
              {t(s_classroom.help)}
            </Col>
            <Col auto>
              <div onClick={onClose} className="cursor-pointer">
                <FeClose size={20} />
              </div>
            </Col>
          </div>
        </div>
        <div ref={chatRef} className="max-h-[500px] min-h-[200px] overflow-y-auto bg-gray-50 px-[32px] py-[16px]">
          <div className="mb-[4px] flex max-w-[400px] justify-start">
            <div className="msg_container ml-[2px]">
              <RingleAvatar />
              <div className="mt-[2px] flex flex-wrap justify-end">
                <Button xs outlined m4 onClick={(e) => handleStudentChat(e, t(s_classroom.audio_problem), 'sound')}>
                  {t(s_classroom.audio_problem)}
                </Button>
                <Button xs outlined m4 onClick={(e) => handleStudentChat(e, t(s_classroom.video_problem), 'video')}>
                  {t(s_classroom.video_problem)}
                </Button>
                <Button
                  xs
                  outlined
                  m4
                  onClick={(e) => handleStudentChat(e, t(s_classroom.docs_problem), 'google_docs')}
                >
                  {t(s_classroom.docs_problem)}
                </Button>
                <Button xs outlined m4 onClick={(e) => handleStudentChat(e, t(s_classroom.zoom_problem), 'zoom')}>
                  {t(s_classroom.zoom_problem)}
                </Button>
              </div>
            </div>
          </div>
          {chatList.map((item, i) =>
            item.user_id == 1 ? (
              <div key={i} className="msg_container animated fadeIn mr-auto mb-[3px]">
                <RingleAvatar />
                <div className="text-h5 mt-[2px] mr-auto max-w-[330px] rounded-[4px] border border-gray-200 bg-white py-[8px] px-[12px] text-left font-normal text-black">
                  <NoSSR>{item.content && Parser(item.content)}</NoSSR>
                </div>
              </div>
            ) : (
              <div key={i} className="msg_container animated fadeIn mb-[3px] text-right">
                <div className="text-right text-[12px] text-gray-300">{item.date}</div>
                <div className="text-h5 mt-[2px] ml-auto inline-block max-w-[330px] rounded-[4px] border border-gray-200 bg-white py-[8px] px-[12px] text-right font-normal text-black">
                  <NoSSR>{item.content && Parser(item.content)}</NoSSR>
                </div>
              </div>
            )
          )}
          <div ref={endOfDivRef} />
        </div>
        <div className="px-[16px] py-[16px]">
          {/*  */}
          {/*  */}
          {/*  */}
          <form onSubmit={(e) => handleStudentChat(e, message, null)}>
            <div className="row flex items-center justify-center">
              <Col>
                <input
                  type="text"
                  className="w-full border-gray-300"
                  style={{ borderRadius: 4, height: 52 }}
                  id="chat-message"
                  ref={inputRef}
                  placeholder={t(s_classroom.write_your_message)}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Col>
              <Col auto w120>
                {loading ? (
                  <Button sm primary full>
                    <LoadingSpinner sm show />
                  </Button>
                ) : (
                  <Button type="submit" sm primary full>
                    {t(s_common.send)}
                  </Button>
                )}
              </Col>
            </div>
          </form>
        </div>
      </div>
    </Draggable>
  )
}

export const RingleAvatar = () => {
  const { t } = useTranslation()
  return (
    <div className="mb-[4px] flex">
      <img src={RINGLE_LOGO_ROUND} className="user_img_msg mr-[4px] w-[20px] rounded-full" />
      {t(s_common.ringle)}
    </div>
  )
}
