// @ts-strict-ignore
import { useState } from 'react'
import Draggable from 'react-draggable'
import Col from '../../components/basic/Col'
import { BxTransfer, FeClose } from '../../components/common/Icons'
import apis from '../../modules/apis'
import { axiosPost } from '../../modules/axiosHelper'
import { s_lesson } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import Button from '../_legacy/Button'
import CardTextarea from '../_legacy/CardTextarea'
import LoadingSpinner from './LoadingSpinner'

type GoogleTranslatePropsType = {
  lessonId: any
  onClose: any
}

const GoogleTranslate = ({ lessonId, onClose }: GoogleTranslatePropsType) => {
  const { t } = useTranslation()
  const [langMode, setLangMode] = useState('en_ko')
  const [text, setText] = useState('')
  const [translatedText, setTranslateText] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChangeLang = (e) => {
    e.preventDefault()
    if (langMode == 'en_ko') {
      setLangMode('ko_en')
    } else {
      setLangMode('en_ko')
    }
  }

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const triggerChange = async (e) => {
    e.preventDefault()
    setLoading(true)
    const response = await axiosPost(apis.lessons.translate(lessonId), {
      text: text,
      mode: langMode,
    })
    setLoading(false)
    if (response.success) {
      setTranslateText(response.translated)
    } else {
      alert(response.message)
    }
  }
  return (
    <Draggable handle=".drag-handle" bounds=".body">
      <div
        style={{ zIndex: 1070 }}
        className="border-1 absolute right-[10px] top-[10px] min-w-[600px] rounded-[5px] border-gray-100 bg-white shadow-md"
      >
        <div className="drag-handle flex cursor-move items-center px-[32px] pt-[32px] pb-[16px]">
          <Col>
            <div className="text-h2 mb-0 font-bold">{t(s_lesson.google_translator)}</div>
          </Col>
          <Col auto>
            <a onClick={onClose} className="cursor-pointer">
              <FeClose size={20} />
            </a>
          </Col>
        </div>
        <div className="px-[32px] pt-[16px] pb-[24px]">
          <div className="row">
            <Col textCenter>
              <div className="border-1 mb-[12px] rounded-[4px] border-gray-300 py-[10px]">
                {langMode === 'en_ko' ? 'English' : 'Korean'}
              </div>
              <CardTextarea
                minRows={5}
                maxRows={5}
                cardClass="w-full"
                onBlur={handleChange}
                placeholder="Enter Text"
                className="text-h5 text-gray-700"
              />
            </Col>
            <Col auto px0>
              <div onClick={handleChangeLang} className="mt-[12px] cursor-pointer text-purple-500">
                <BxTransfer size={20} />
              </div>
            </Col>
            <Col clx="text-center">
              <div className="border-1 mb-[12px] rounded-[4px] border-gray-300 py-[10px]">
                {langMode === 'en_ko' ? 'Korean' : 'English'}
              </div>
              <CardTextarea
                minRows={5}
                maxRows={5}
                cardClass="w-full"
                value={translatedText}
                disabled
                placeholder="Translation"
                className="text-h5 bg-gray-100 text-gray-700"
                bodyClass="bg-gray-100"
              />
            </Col>
          </div>
          <div className="row mt-[16px]">
            <Col></Col>
            <Col auto>
              {loading ? (
                <Button primary md>
                  <LoadingSpinner show={true} />
                </Button>
              ) : (
                <Button onClick={triggerChange} primary md clx="text-h5">
                  {t(s_lesson.translate)}
                </Button>
              )}
            </Col>
          </div>
        </div>
      </div>
    </Draggable>
  )
}
export default GoogleTranslate
